import Navbar from "components/Navigation/Navbar";
import { useState } from "react";
import "./app.scss";
import * as MdIcons from "react-icons/md";
import { Link, Route, Routes } from "react-router-dom";
import Main from "page/Main";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Sourcing from "page/sourcing/Sourcing";
import { Container } from "@mui/material";
import Account from "page/account/main";

function App() {
  const [showNav, setShowNav] = useState(true);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="ck-header-wrap">
        <div className="ck-header">
          <MdIcons.MdOutlineMenu
            className="ck-header-hamburger"
            size="24"
            onClick={() => setShowNav((prev) => !prev)}
          />
          <div className="ck-logo">
            <Link to="/">CHEEKO</Link>
          </div>
          <div className="etc-buttons">
            <ul>
              <li className="user-info">
                <Link to="/MyPage">
                  <strong className="user-name">홍길동</strong>
                  <span>님</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`ck-main-body-wrap ${showNav ? "show-nav" : "hide-nav"}`}>
        <div className="ck-nav">
          <div className="ck-side-menu">
            <Navbar />
          </div>
        </div>
        <div className="ck-content">
          <Container maxWidth={false}>
            <Routes>
              <Route path="/" element={<Main />}></Route>
              <Route path="/account/*" element={<Account />}></Route>
              <Route path="/sourcing/*" element={<Sourcing />}></Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
}

export default App;
