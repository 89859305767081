import { Box, Stack, Modal, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { getRecordDetail } from "apis/sourcing/sourcing";
import { RecordDetailData, WonbuData } from "../interfaces";
import { format, zonedTimeToUtc } from "date-fns-tz";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { isEmpty } from "utils/utils";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: "50%",
  p: 4,
};

interface props {
  naver_id: number;
  search_id: number;
  wonbu: WonbuData;
}
const SourcingDetail = ({ wonbu, naver_id, search_id }: props) => {
  const { code, title } = wonbu;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const [records, setRecords] = useState<RecordDetailData[]>([]);

  // chart js test
  const data = {
    // labels 사용 안 함
    labels: records.map((record) => {
      return format(zonedTimeToUtc(record.createAt, "UTC"), "yyyy.MM.dd");
    }),
    datasets: [
      {
        type: "line" as const,
        label: "순위",
        yAxisID: "rank",
        borderColor: "rgb(54, 162, 235)",
        borderWidth: 2,
        data: records.map((record) => {
          return {
            x: format(zonedTimeToUtc(record.createAt, "UTC"), "yyyy.MM.dd"),
            y: record.rank,
            record: record,
          };
        }),
      },
      {
        type: "bar" as const,
        label: "최저가",
        yAxisID: "lowPrice",
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "#FFB1C1",
        borderWidth: 2,
        data: records.map((record) => {
          return {
            x: format(zonedTimeToUtc(record.createAt, "UTC"), "yyyy.MM.dd"),
            y: record.lowPrice,
            record: record,
          };
        }),
      },
    ],
  };
  const options = {
    // 옵션 (1)
    responsive: true,
    // 옵션 (2)
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    // 옵션 (3)
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      rank: {
        position: "left" as const,
        min: !isEmpty(records)
          ? records.reduce((prev, value) => {
              return prev.rank <= value.rank ? prev : value;
            }).rank - 1
          : 0,
        max: !isEmpty(records)
          ? records.reduce((prev, value) => {
              return prev.rank >= value.rank ? prev : value;
            }).rank + 1
          : 10,
        ticks: {
          stepSize: 1,
        },
        reverse: true,
        grid: {
          color: "#E3E3E3",
        },
      },
      lowPrice: {
        position: "right" as const,
        min: !isEmpty(records)
          ? records.reduce((prev, value) => {
              return prev.lowPrice <= value.lowPrice ? prev : value;
            }).lowPrice - 10000
          : 0,
        max: !isEmpty(records)
          ? records.reduce((prev, value) => {
              return prev.lowPrice >= value.lowPrice ? prev : value;
            }).lowPrice + 10000
          : 10,
        ticks: {
          stepSize: 5000,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "테스트 차트",
      },
      tooltip: {
        callbacks: {
          afterBody: (contexts: any) => {
            const record = contexts[0].raw.record;
            return `리뷰수 : ${record.reviewCount}\n판매처 : ${record.sellerCount}`;
          },
        },
      },
    },
  };

  useEffect(() => {
    const fetchProductRecord = async () => {
      if (naver_id) {
        const parmas = { naver_id, search_id };
        const data = await getRecordDetail(parmas);
        if (data) {
          setRecords(data);
        }
      }
    };
    if (open) {
      fetchProductRecord();
    }
  }, [naver_id, search_id, open]);

  return (
    <>
      <Button onClick={handleOpen}>그래프</Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box>
            <div
              style={{
                marginTop: "16px",
                fontSize: "2rem",
                textAlign: "center",
              }}
            >
              <a
                href={`https://search.shopping.naver.com/catalog/${code}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {!isEmpty(records) ? title : "loding..."}
              </a>
            </div>
          </Box>
          <Box>
            <Stack direction="row">
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                showDisabledMonthNavigation
              />
              <span> - </span>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                showDisabledMonthNavigation
              />
            </Stack>
          </Box>
          <Box minHeight={"300px"}>
            <Chart type="bar" data={data} options={options} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SourcingDetail;
