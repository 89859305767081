import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  deleteAccount,
  getAccountList,
  getSiteList,
  postAccount,
  updateAccount,
} from "apis/account/account";
import CustomTable from "components/CustomTable/CustomTable";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SelectColumnFilter } from "components/CustomTable/filters/SelectColumnFilter";
import { CellProps, TableInstance } from "react-table";
import { isEmpty } from "utils/utils";
import notify from "utils/notify";
import { NumberRangeColumnFilter } from "components/CustomTable/filters";

export interface site {
  name: string;
  subName: string;
  id: number;
}

export interface category {
  id: number;
  text: string;
}

export interface state {
  id: number;
  text: string;
}

export interface account {
  state_id: number;
  name: string;
  pw: string;
  tel?: string;
  email?: string;
  num: number;
  site_id: number;
  category_id: number;
  id: string;
  birthday?: string;
  note?: string;
  category: category;
  site: site;
  state: state;
}

export const accountDefault = {
  state_id: 1,
  category_id: 1,
};

const OrderAccount = () => {
  const [open, setOpen] = useState(false);
  const [sites, setSites] = useState<readonly site[]>([]);
  const siteLoading = open && sites.length === 0;
  const [siteNum, setSiteNum] = useState("");
  const [accountData, setAccountData] = useState<account[]>([]);

  // CRUD
  const createAccount = useCallback(
    async (
      instance: TableInstance<account>,
      data?: Record<string, string | number>
    ) => {
      if (data) {
        if (!data["num"]) {
          const lastNum = accountData.at(-1)?.num;
          data["num"] = lastNum ? lastNum + 1 : 1;
        }
        data["site_id"] = siteNum;
        const resData = await postAccount({ ...accountDefault, ...data });
        setAccountData([...accountData.slice(), resData]);
      }
    },
    [accountData, siteNum]
  );

  const onUpdateAccount = useCallback(
    async (
      instance: TableInstance<account>,
      data?: Record<string, string | number>
    ) => {
      if (data) {
        const newData: account[] = [];
        for (let index = 0; index < instance.selectedFlatRows.length; index++) {
          const selectedFlatRow = instance.selectedFlatRows[index];
          const updataData: account = await updateAccount({
            ...data,
            site_id: selectedFlatRow.original.site_id,
            num: selectedFlatRow.original.num,
          });
          newData.push(updataData);
        }
        const filteredData = accountData.map((itemA) => {
          const matchingItem = newData.find(
            (itemB) =>
              itemB.site_id === itemA.site_id && itemB.num === itemA.num
          );
          return matchingItem ? matchingItem : itemA;
        });
        setAccountData(filteredData);
      }
    },
    [accountData]
  );

  const onDeleteAccount = useCallback(
    async (
      instance: TableInstance<account>,
      data?: Record<string, string | number>
    ) => {
      instance.selectedFlatRows.forEach((v) => {
        deleteAccount(v.original.site_id, v.original.num);
      });

      const count = instance.selectedFlatRows.length;
      const delData = instance.selectedFlatRows.map((v) => {
        return { site_id: v.original.site_id, num: v.original.num };
      });
      const filteredData = accountData.filter(
        (itemB) =>
          !delData.some(
            (itemA) =>
              itemA.site_id === itemB.site_id && itemA.num === itemB.num
          )
      );
      notify.success(count + "개 삭제 성공");
      setAccountData(filteredData);
    },
    [accountData]
  );

  // 필터
  const [searchText, setSearchText] = useState(""); // 글로벌 검색 텍스트

  const handleChangeSearch = (text: string) => {
    setSearchText(text);
    if (tableRef.current) {
      tableRef.current.setGlobalFilter(text);
    }
  };

  const confirmationList = () => {
    if (tableRef.current) {
      const oldFilter = tableRef.current.state.filters;
      const check = oldFilter.filter(
        (old: { id: string; value: any }) => old.id === "state.text"
      );
      if (isEmpty(check)) {
        tableRef.current.setFilter("state.text", "확인필요");
      } else {
        tableRef.current.setAllFilters(
          oldFilter.filter(
            (old: { id: string; value: any }) => old.id !== "state.text"
          )
        );
      }
    }
  };

  // 테이블 설정
  const stateItems = useMemo(
    () => [
      { value: 1, text: "미인증" },
      { value: 2, text: "사용" },
      { value: 3, text: "미사용" },
      { value: 4, text: "정지" },
      { value: 5, text: "확인필요" },
    ],
    []
  );

  const categoryItems = useMemo(
    () => [
      { value: 1, text: "간편" },
      { value: 2, text: "일반" },
      { value: 3, text: "사업자" },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        accessor: "state.text",
        Header: "상태",
        minWidth: 60,
        width: 60,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ cell: { value } }: CellProps<account>) => {
          const checkColor = ["미사용", "정지"].includes(value)
            ? "red"
            : ["미인증", "확인필요"].includes(value)
            ? "yellow"
            : "green";
          return (
            <div className="circle-wrap">
              <div className={`circle ${checkColor}`} color={checkColor}></div>
              <div className="circle-text">{value}</div>
            </div>
          );
        },
        dialog: {
          accessor: "state_id",
          input: { type: "select", child: stateItems },
          update: { isMulti: true },
        },
      },
      {
        accessor: "num",
        Header: "번호",
        minWidth: 37,
        width: 37,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "name",
        Header: "이름",
        minWidth: 90,
        width: 90,
      },
      {
        accessor: "id",
        Header: "아이디",
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "pw",
        Header: "비밀번호",
      },
      {
        accessor: "tel",
        Header: "전화번호",
        dialog: { input: { type: "tel" } },
      },
      {
        accessor: "email",
        Header: "이메일",
        dialog: { input: { type: "email" } },
      },
      {
        accessor: "birthday",
        Header: "생일",
        dialog: { input: { type: "date", format: "yyyyMMdd" } },
      },
      {
        accessor: "note",
        Header: "비고",
      },
      {
        accessor: "category.text",
        Header: "타입",
        Filter: SelectColumnFilter,
        dialog: {
          accessor: "category_id",
          input: { type: "select", child: categoryItems },
        },
      },
    ],
    [stateItems, categoryItems]
  );
  const tableData = useMemo(() => accountData, [accountData]);

  const initialState = {
    pageSize: 10,
    hiddenColumns: ["tel", "category.text", "birthday", "email"],
  };

  const tableRef = useRef<any>(null);

  //
  useEffect(() => {
    if (siteNum) {
      const fectchAccounts = async () => {
        const data: account[] = await getAccountList(siteNum);
        setAccountData(data);
      };
      fectchAccounts();
    }
  }, [siteNum]);

  useEffect(() => {
    if (siteLoading) {
      const fectchSites = async () => {
        const data: site[] = await getSiteList();
        setSites(data);
      };
      fectchSites();
    }
  }, [siteLoading]);

  return (
    <Stack justifyContent="center" alignItems="center" spacing={2} mt={2}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event: any, newValue: any) => {
            if (newValue) {
              setSiteNum(newValue.id);
            }
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          options={sites.map((site) => {
            return { label: site.name, id: site.id };
          })}
          loading={siteLoading}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="사이트"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {siteLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </Stack>
      <Grid container mt={16}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          xs={12}
        >
          <Grid container item alignItems="center" xs={6}>
            <Grid item>
              <TextField
                label="검색"
                size="small"
                value={searchText}
                onChange={(e) => handleChangeSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Button variant="text" onClick={confirmationList}>
                  확인필요
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", marginTop: "2rem" }}>
        {tableData && (
          <CustomTable
            name={"orderAccountTable"}
            columns={columns}
            data={tableData}
            onAdd={createAccount}
            onEdit={onUpdateAccount}
            onDelete={onDeleteAccount}
            initialState={initialState}
            ref={tableRef}
          />
        )}
      </Box>
    </Stack>
  );
};

export default OrderAccount;
