import { Box, TextField, Stack, MenuItem } from "@mui/material";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { dialogDefault } from "./defaults";
import { DetailField, DialogColumn, DialogField } from "./Interface";

type DialogBoxProps = {
  type: string;
  columns?: DialogColumn[];
  data: any;
  setData: (key: string, value: any) => void;
};

const DialogCustomContent = (props: DialogBoxProps) => {
  const { type, columns, data, setData } = props;

  const onChangeHandler = (e: any) => {
    setData(e.target.name, e.target.value);
  };

  const setHidden = (detail: DetailField) => {
    if (type.indexOf("delete") !== -1) {
      return true;
    } else if (type.indexOf("multi") !== -1) {
      if (detail.isMulti) {
        return false;
      } else {
        return true;
      }
    } else {
      return detail.isHidden;
    }
  };

  const setDefault = (id: string, setting: DialogField) => {
    let defaultValue: Record<string, string> | string = "";
    if (setting.input.type === "select" && type === "create") {
      defaultValue = setting.input.child[0].value;
    } else {
      if (id.indexOf(".") !== -1) {
        let ids = id.split(".");
        for (let index = 0; index < ids.length; index++) {
          const key = ids[index];
          if (index === 0) {
            defaultValue = data[key];
          } else {
            if (typeof defaultValue === "object") {
              defaultValue = defaultValue[key];
            }
          }
        }
      } else {
        defaultValue = data[id];
      }
      if (
        typeof defaultValue === "string" ||
        typeof defaultValue === "number"
      ) {
        if (setting.input.type === "datetime-local") {
          defaultValue = format(
            zonedTimeToUtc(defaultValue, "UTC"),
            "yyyy-MM-dd hh:mm"
          ).replace(" ", "T");
        } else if (setting.input.type === "date") {
          defaultValue = format(
            zonedTimeToUtc(defaultValue, "UTC"),
            "yyyy-MM-dd"
          );
        }
      }
    }
    return defaultValue ? defaultValue : "";
  };

  return (
    <Box>
      <Stack spacing={2}>
        {columns &&
          columns.map((column, idx: number) => {
            const { id, Header, dialog } = column;
            const setting = { ...dialogDefault, ...dialog };
            const { input } = setting;
            const detail: DetailField = setting[type.split("-")[0]];

            return (
              <TextField
                style={{ marginTop: "10px" }}
                key={idx}
                autoComplete="off"
                required={detail.isRequired}
                id={id}
                label={Header}
                onChange={(e) => onChangeHandler(e)}
                select={input.type === "select"}
                name={setting.accessor ? setting.accessor : id}
                defaultValue={setDefault(
                  setting.accessor ? setting.accessor : id,
                  setting
                )}
                hidden={setHidden(detail)}
                disabled={
                  type.indexOf("delete") !== -1 ? true : detail.isDisabled
                }
                type={input.type}
              >
                {input.type === "select" &&
                  input.child.map((child: any, index: number) => {
                    return (
                      <MenuItem key={index} value={child.value}>
                        {child.text}
                      </MenuItem>
                    );
                  })}
              </TextField>
            );
          })}
      </Stack>
    </Box>
  );
};

export default DialogCustomContent;
