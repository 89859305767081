import { useMemo } from "react";
import { FilterProps } from "react-table";
import { getMinMax, useActiveElement } from "./utils";
import { InputLabel, TextField } from "@mui/material";

export const NumberRangeColumnFilter = ({
  column: { filterValue = [], render, preFilteredRows, setFilter, id },
}: FilterProps<any>) => {
  const [min, max] = useMemo(
    () => getMinMax(preFilteredRows, id),
    [id, preFilteredRows]
  );
  const focusedElement = useActiveElement();
  const hasFocus =
    focusedElement &&
    (focusedElement.id === `${id}_1` || focusedElement.id === `${id}_2`);
  return (
    <>
      <InputLabel htmlFor={id} shrink focused={!!hasFocus}>
        {render("Header")}
      </InputLabel>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          paddingTop: 5,
        }}
      >
        <TextField
          id={`${id}_1`}
          value={filterValue[0] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old: any[] = []) => [
              val ? parseInt(val, 10) : undefined,
              old[1],
            ]);
          }}
          placeholder={`Min (${min})`}
          style={{
            marginRight: "0.5rem",
          }}
          variant="standard"
        />
        to
        <TextField
          id={`${id}_2`}
          value={filterValue[1] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old: any[] = []) => [
              old[0],
              val ? parseInt(val, 10) : undefined,
            ]);
          }}
          placeholder={`Max (${max})`}
          style={{
            marginLeft: "0.5rem",
          }}
          variant="standard"
        />
      </div>
    </>
  );
};
