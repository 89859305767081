import { ChangeEvent, useEffect, useState } from "react";
import { FilterProps } from "react-table";
import { TextField } from "@mui/material";
import { findFirstColumn } from "./utils";

export const DefaultColumnFilter = <T extends Record<string, unknown>>({
  columns,
  column,
  gotoPage,
}: FilterProps<T>) => {
  const { id, filterValue, setFilter, render } = column;
  const [value, setValue] = useState(filterValue || "");
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  // ensure that reset loads the new value
  useEffect(() => {
    setValue(filterValue || "");
  }, [filterValue]);

  const isFirstColumn = findFirstColumn(columns) === column;
  return (
    <TextField
      name={id}
      label={render("Header")}
      InputLabelProps={{ htmlFor: id }}
      value={value}
      autoFocus={isFirstColumn}
      variant="standard"
      onChange={handleChange}
      onBlur={(e) => {
        const value = e.target.value || undefined;
        setFilter(value);
        if (value !== filterValue) gotoPage(0);
      }}
    />
  );
};
