import SourcingChart from "components/sourcing/Chart/SourcingChart";
import SourcingIgnore from "components/sourcing/ignore/Ignore";
import SourcingManager from "components/sourcing/manager/manager";
import { Route, Routes } from "react-router-dom";

const Sourcing = () => {
  return (
    <Routes>
      <Route path="chart" element={<SourcingChart />}></Route>
      <Route path="manager" element={<SourcingManager />}></Route>
      <Route path="ignore" element={<SourcingIgnore />}></Route>
    </Routes>
  );
};

export default Sourcing;
