import { useState, PropsWithChildren } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import DialogCustomContent from "./DialogCustomContent";
import { Row } from "react-table";

type DialogBoxProps<T extends Record<string, unknown>> = {
  title?: string;
  open: boolean;
  onClose: (approved: boolean) => void;
  type: string;
  onApprove?: (data?: Record<string, unknown>) => void;
  onReject?: () => void;
  columns?: any;
  datas?: Row<T>[];
};

const DialogBox = <T extends Record<string, unknown>>({
  title,
  open,
  onClose,
  type,
  onApprove,
  onReject,
  datas,
  columns,
}: PropsWithChildren<DialogBoxProps<T>>) => {
  const [updateData, setUpdateData] = useState({});

  const setData = (id: string, value: any) => {
    setUpdateData((old) => {
      let tempData: Record<string, unknown> = Object.assign({}, old);
      tempData[id] = value;
      return tempData;
    });
  };

  const handleClose = (approved: boolean) => {
    setUpdateData({});
    onClose(approved);
  };

  const handleApprove = () => {
    onApprove && onApprove(updateData);
    handleClose(true);
  };

  const handleReject = () => {
    if (onReject) {
      onReject();
    }
    handleClose(false);
  };

  const titleName = () => {
    if (!title) return type + " Dialog";
    if (type === "create") return title + " 추가";
    else if (type === "update") return title + " 수정";
    else if (type === "delete") return title + " 삭제";
    else return title;
  };

  const contentElement = () => {
    if (type !== "create") {
      if (datas?.length === 1) {
        return (
          <DialogCustomContent
            type={type}
            columns={columns}
            setData={setData}
            data={datas[0].original}
          />
        );
      } else if (datas && datas?.length > 1) {
        if (type === "update") {
          return (
            <>
              <span>{datas?.length}개의 데이터</span>
              <DialogCustomContent
                type={`${type}-multi`}
                columns={columns}
                setData={setData}
                data={updateData}
              />
            </>
          );
        }
        return <span>{datas?.length}개의 데이터</span>;
      }
    }
    return (
      <DialogCustomContent
        type={type}
        columns={columns}
        setData={setData}
        data={updateData}
      />
    );
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>{titleName()}</DialogTitle>
      <DialogContent>{contentElement()}</DialogContent>
      <DialogActions>
        {onApprove && <Button onClick={handleApprove}>승인</Button>}
        {onReject && <Button onClick={handleReject}>거절</Button>}
        <Button onClick={() => handleClose(false)}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
