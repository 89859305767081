import { submenu } from "./menuItemDatas";
import {Link} from "react-router-dom"

interface PropsType {
    submenus: submenu[];
    dropdown: boolean;
}
const SubMenuItem = ({ submenus, dropdown }:PropsType) => {
    return (
      <ul className={`sub-menu-wrap ${dropdown?"show-sub-menu":"hide-sub-menu"}`}>
        {submenus.map((submenu, index) => (
          <li key={index} className="menu-item">
            <Link to={submenu.url} className="menu-item-wrap">
              <div className="menu-icon"></div>
              <div className="menu-text">{submenu.title}</div>
              <div className="drop-icon"></div>
            </Link>
          </li>
        ))}
      </ul>
    );
  };
  
  export default SubMenuItem;