import {
  PaginationResponse,
  SearchData,
  RecordData,
  RecordDetailData,
  IgnoreWonbuData,
  BaseResponse,
} from "components/sourcing/interfaces";
import notify from "utils/notify";
import { defaultInstance } from "../customAxios";

const BaseURL = "sourcing";

export const getSearchDatas = async () => {
  try {
    const { data } = await defaultInstance.get<SearchData[]>(
      `${BaseURL}/search/`
    );
    return data;
  } catch (error) {
    notify.error("소싱 카테고리 가져오기 실패");
    console.error(error);
    return [];
  }
};

export const getSourcingDates = async (searchId: number) => {
  try {
    const { data } = await defaultInstance.get(`${BaseURL}/record/datelist/`, {
      params: { search_id: searchId },
    });
    if (data.msg) {
      notify.error("소싱데이터가 충분하지 않습니다. 다음날 재시도 해주세요.");
      return [];
    }
    return data.result;
  } catch (error) {
    notify.error("소싱 데이터 날짜 가져오기 실패");
    console.error(error);
    return [];
  }
};

export const getRecords = async (params: any) => {
  try {
    const { data } = await defaultInstance.get<PaginationResponse<RecordData>>(
      `${BaseURL}/record/`,
      {
        params: params,
      }
    );
    return data;
  } catch (error) {
    notify.error("실패!!!!");
    console.error(error);
    return { result: [] };
  }
};

export const getRecordDetail = async (params: any) => {
  try {
    const { data } = await defaultInstance.get<RecordDetailData[]>(
      `${BaseURL}/record/detail/`,
      {
        params: params,
      }
    );
    return data;
  } catch (error) {
    notify.error("기록 가져오기 실패");
    console.error(error);
    return [];
  }
};

export const postSearchData = async (data: SearchData) => {
  try {
    const res = await defaultInstance.post<any>(`${BaseURL}/search/`, data);
    notify.success("소싱 카테고리 등록 성공");
    return res.data;
  } catch (error) {
    notify.error("소싱 카테고리 등록 실패");
    console.error(error);
    return [];
  }
};

export const delectSearchData = async (search_id: number) => {
  try {
    const res = await defaultInstance.delete<any>(
      `${BaseURL}/search/${search_id}`
    );
    notify.success("소싱 카테고리 삭제 성공");
    return res.data;
  } catch (error) {
    notify.error("소싱 카테고리 삭제 실패");
    console.error(error);
    return [];
  }
};

export const getIgnore = async (params: any) => {
  try {
    const { data } = await defaultInstance.get<
      PaginationResponse<IgnoreWonbuData>
    >(`${BaseURL}/wonbu/ignore/`, {
      params: params,
    });
    return data;
  } catch (error) {
    notify.error("실패!!!!");
    console.error(error);
    return { result: [] };
  }
};

export const putIgnore = async (data: any) => {
  try {
    const res = await defaultInstance.put<BaseResponse<any>>(
      `${BaseURL}/wonbu/ignore/`,
      data
    );
    return res.data;
  } catch (error) {
    notify.error("실패!!!!");
    console.error(error);
    return { result: [] };
  }
};
