import { useState } from "react";
import SubMenuItem from "./SubMenuItem";
import { topMenu } from './menuItemDatas';
import * as MdIcons from "react-icons/md";
import { Link } from "react-router-dom";

interface PropsType {
  menu: topMenu
}

const MenuItem = ({menu}:PropsType) => {
  const [dropdown, setDropdown] = useState(false);

  const menuClicekd = () => {
    if(menu.submenu){
      setDropdown((prev)=>!prev)
    }else{

    }
  }
  return (
    <li className="menu-item">
        <Link to={menu.submenu?'#':menu.url} className="menu-item-wrap" onClick={() => menuClicekd()}>
          <div className="menu-icon">{menu.icon}</div>
          <div className={`menu-text ${dropdown?"open":""}`}>{menu.title}</div>
          <div className="drop-icon">{menu.submenu? dropdown?<MdIcons.MdOutlineKeyboardArrowUp size="24" />:<MdIcons.MdKeyboardArrowDown size="24" /> : <MdIcons.MdKeyboardArrowRight size="24" />}</div>
        </Link>
      {menu.submenu ? <SubMenuItem submenus={menu.submenu} dropdown={dropdown} />:""}
    </li>
  );
};
  
export default MenuItem;