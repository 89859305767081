import {
  useMemo,
  useEffect,
  useState,
  useRef,
  Fragment,
  useCallback,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { SearchData, RecordData } from "components/sourcing/interfaces";
import { isEmpty, numberWithCommas } from "utils/utils";
import "./sourcingChart.scss";
import { addDays } from "date-fns";
import {
  getSourcingDates,
  getRecords,
  getSearchDatas,
  putIgnore,
} from "apis/sourcing/sourcing";
import { NumberRangeColumnFilter } from "components/CustomTable/filters";
import CustomTable from "components/CustomTable/CustomTable";
import notify from "utils/notify";
import { getProductAllTemp } from "apis/productManager/productManager";
import { SelectColumnFilter } from "components/CustomTable/filters/SelectColumnFilter";
import { CellProps, Row, TableInstance } from "react-table";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SourcingDetail from "./SourcingDetail";

const SourcingChart = () => {
  // 카테고리 관련
  const [open, setOpen] = useState(false);
  const [registProducts, setRegistProducts] = useState<any[]>([]);
  const [sourcingCategorys, setSourcingCategorys] = useState<
    readonly SearchData[]
  >([]); // 소싱카테고리들
  const categoryLoading = open && sourcingCategorys.length === 0;
  const [selectedCategoryId, setSelectedCategoryId] = useState(""); // 데이터 확인용 소싱카테고리 아이디

  // 날짜 관련
  const [sourcingDates, setSourcingDates] = useState<Date[]>([]); // 데이터 존재 하는 날짜
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(); // 선택한 데이터 날짜
  const [comparisonDate, setComparisonDate] = useState<Date | undefined>(); // 비교 할 데이터 날짜

  // 데이터 관련
  const [selectedProducts, setSelectedProducts] = useState<RecordData[]>([]); // 선택한 데이터
  const [comparisonProducts, setComparisonProducts] = useState<RecordData[]>(
    []
  ); // 비교 할 데이터
  const [calcProducts, setCalcProducts] = useState<RecordData[]>([]); // 계산된 데이터
  const [dataLoading, setDataLoading] = useState(false);

  // 필터 관련
  const [searchText, setSearchText] = useState(""); // 글로벌 검색 텍스트

  // filter
  const initialState = {
    pageSize: 100,
    filters: [{ id: "rank", value: [null, 200] }],
  };

  const tableRef = useRef<any>(null);

  const fetchSourcingDates = async (searchId: string) => {
    try {
      if (searchId) {
        const data = await getSourcingDates(parseInt(searchId));
        if (isEmpty(data)) {
          resetData();
          return [undefined, undefined];
        }
        const cnvt_date: Date[] = data.map((e: string) => {
          return new Date(e);
        });
        let selectedDate,
          comparisonDate = undefined;
        if (cnvt_date.length > 1) {
          selectedDate = cnvt_date[cnvt_date.length - 1];
          comparisonDate = cnvt_date[cnvt_date.length - 2];
        } else if (cnvt_date.length === 1) {
          selectedDate = cnvt_date[cnvt_date.length - 1];
        }
        setSelectedDate(selectedDate);
        setComparisonDate(comparisonDate);
        setSourcingDates(cnvt_date);
        return [selectedDate, comparisonDate];
      }
    } catch (e) {
      console.error(e);
    }
    return [undefined, undefined];
  };

  const columns = useMemo(
    () => [
      {
        accessor: "rank",
        Header: "순위",
        minWidth: 80,
        width: 80,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "calcRank",
        Header: "변동",
        minWidth: 80,
        width: 80,
        Cell: ({ cell: { value } }: any) => {
          let color = "black";
          if (value > 0) {
            color = "blue";
          } else if (value < 0) {
            color = "red";
          } else {
            value = "";
          }
          return <span style={{ color: color }}>{value}</span>;
        },
        Filter: NumberRangeColumnFilter,
        filter: "between",
        sortType: (rowA: Row<RecordData>, rowB: Row<RecordData>) => {
          const a = rowA.original.calcRank;
          const b = rowB.original.calcRank;
          if (a > b) return 1;
          if (b > a) return -1;
          return 0;
        },
        tooltip: "이전 날짜와 최근 날짜의 데이터의 차이",
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "wonbu.title",
        Header: "상품명",
        minWidth: 300,
        width: 300,
        Cell: ({
          cell: {
            value,
            row: {
              original: { search_id, naver_id, wonbu },
            },
          },
        }: any) => {
          const url = `https://search.shopping.naver.com/catalog/${wonbu.code}`;
          return (
            <div>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {value}
              </a>
              <SourcingDetail
                wonbu={wonbu}
                naver_id={naver_id}
                search_id={search_id}
              />
            </div>
          );
        },
        tooltip: "원부 이름",
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "reviewCount",
        Header: "리뷰 수",
        minWidth: 80,
        width: 80,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "sellerCount",
        Header: "판매처",
        minWidth: 80,
        width: 80,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "wonbu.registerAt",
        Header: "등록일",
        minWidth: 100,
        width: 100,
        Cell: ({ cell: { value } }: any) => {
          return format(zonedTimeToUtc(value, "UTC"), "yyyy.MM");
        },
        tooltip: "가격비교 사이트에 원부가 등록된 날짜",
        dialog: {
          input: { type: "datetime-local" },
          update: { isDisabled: true },
        },
      },
      {
        accessor: "lowPrice",
        Header: "최저가",
        minWidth: 120,
        width: 120,
        Cell: ({ cell: { value } }: CellProps<RecordData>) => {
          return numberWithCommas(value) + "원";
        },
        Filter: NumberRangeColumnFilter,
        filter: "between",
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "registCheekoState",
        Header: "치코상태",
        minWidth: 120,
        width: 120,
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value } }: CellProps<RecordData>) => {
          const checkColor =
            value === "미등록"
              ? "red"
              : ["품절", "중지"].includes(value)
              ? "yellow"
              : "green";
          return (
            <div className="circle-wrap">
              <div className={`circle ${checkColor}`} color={checkColor}></div>
              <div className="circle-text">{value}</div>
            </div>
          );
        },
        dialog: { update: { isDisabled: true } },
      },
    ],
    []
  );
  const tableData = useMemo(() => calcProducts, [calcProducts]);

  const fetchProducts = async (currentDate: Date, type: string) => {
    setDataLoading(true);
    try {
      const params = {
        search_id: parseInt(selectedCategoryId),
        page: 1,
        page_size: 9999,
        date: format(currentDate, "yyyyMMdd"),
      };
      const { result } = await getRecords(params);
      if (type === "selected") {
        setSelectedProducts(result);
      } else if (type === "comparison") {
        setComparisonProducts(result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const resetData = () => {
    setCalcProducts([]);
    setComparisonProducts([]);
    setSelectedProducts([]);
    setSelectedDate(undefined);
    setComparisonDate(undefined);
    setSourcingDates([]);
    setSelectedCategoryId("");
  };

  const handleChangeSearch = (text: string) => {
    setSearchText(text);
    if (tableRef.current) {
      tableRef.current.setGlobalFilter(text);
    }
  };

  const suddenRank = () => {
    if (tableRef.current) {
      const oldFilter = tableRef.current.state.filters;
      const check = oldFilter.filter(
        (old: { id: string; value: any }) => old.id === "calcRank"
      );
      if (isEmpty(check)) {
        tableRef.current.setFilter("calcRank", [20]);
      } else {
        tableRef.current.setAllFilters(
          oldFilter.filter(
            (old: { id: string; value: any }) => old.id !== "calcRank"
          )
        );
      }
    }
  };

  const unResigerFilter = () => {
    if (tableRef.current) {
      const oldFilter = tableRef.current.state.filters;
      const check = oldFilter.filter(
        (old: { id: string; value: any }) => old.id === "registCheekoState"
      );
      if (isEmpty(check)) {
        tableRef.current.setFilter("registCheekoState", "미등록");
      } else {
        tableRef.current.setAllFilters(
          oldFilter.filter(
            (old: { id: string; value: any }) => old.id !== "registCheekoState"
          )
        );
      }
    }
  };

  const checkBtnDisabled = (beforeDay: number) => {
    if (dataLoading) {
      return false;
    }
    if (selectedDate) {
      return !!!sourcingDates.find((item) => {
        return (
          item.getTime() ===
          addDays(selectedDate.setHours(9, 0, 0, 0), -beforeDay).getTime()
        );
      });
    } else {
      return false;
    }
  };

  const handleChangeDate = (date: Date, target: string) => {
    if (target === "selected") {
      setSelectedDate(date);
    } else if (target === "comparison") {
      setComparisonDate(date);
    }
    if (selectedCategoryId !== "") {
      fetchProducts(date, target);
    }
  };

  const handleClickSearch = async () => {
    const [date1, date2] = await fetchSourcingDates(selectedCategoryId);
    if (selectedCategoryId !== "") {
      if (date1) {
        fetchProducts(date1, "selected");
      }
      if (date2) {
        fetchProducts(date2, "comparison");
      }
    }
  };

  const handleClickDateRange = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedDate) {
      const beforeDay = e.currentTarget.value;
      handleChangeDate(addDays(selectedDate, -beforeDay), "comparison");
    } else {
      notify.error("기준 날짜 먼저 선택해주세요.");
    }
  };

  const changeIgnore = useCallback(
    async (instance: TableInstance<RecordData>) => {
      const count = instance.selectedFlatRows.length;
      const ignorelist = instance.selectedFlatRows.map((v) => {
        return { id: v.original.naver_id, ignore: true };
      });
      const filteredData = tableData.filter(
        (itemB) => !ignorelist.some((itemA) => itemA.id === itemB.naver_id)
      );
      await putIgnore(ignorelist);
      notify.success(count + "개 예외 처리 성공");
      setCalcProducts(filteredData);
    },
    [tableData]
  );

  useEffect(() => {
    // 데이터 변경시 순위 변동 계산
    const tempProducts = selectedProducts.slice();
    if (selectedProducts) {
      if (!isEmpty(comparisonProducts)) {
        tempProducts.forEach((data) => {
          const findProduct = comparisonProducts.find(
            (x) => x.wonbu.code === data.wonbu.code
          );
          if (findProduct) {
            data.calcRank = findProduct.rank - data.rank;
          } else {
            data.calcRank = 0;
          }
        });
      }
      tempProducts.forEach((data) => {
        data.registCheekoState = "미등록";
        registProducts.forEach((element) => {
          if (element.wonbu.code === data.wonbu.code) {
            data.registCheekoState = element.state.text;
            return;
          }
        });
      });
      setCalcProducts(tempProducts);
    }
    setDataLoading(false);
  }, [comparisonProducts, selectedProducts, registProducts]);

  useEffect(() => {
    // 카테고리 오픈시 불러오기
    if (!categoryLoading) {
      return undefined;
    }
    const fectchSourcingCategorys = async () => {
      const data = await getSearchDatas();
      setSourcingCategorys(data);
    };
    fectchSourcingCategorys();
    // 판매중인 상품 목록 가져오기
    const fetchProductDatas = async () => {
      // const data = await getProductAll();
      const data = await getProductAllTemp();
      setRegistProducts(data);
    };
    fetchProductDatas();
  }, [categoryLoading]);

  return (
    <Stack justifyContent="center" alignItems="center" spacing={2} mt={2}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setSelectedCategoryId(newValue.id);
              }
            }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={sourcingCategorys.map((value) => {
              return { label: value.name, id: value.id };
            })}
            loading={categoryLoading}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="카테고리"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {categoryLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            color="success"
            variant="contained"
            onClick={() => resetData()}
          >
            초기화
          </Button>
          <Button
            variant="contained"
            className="search"
            onClick={() => handleClickSearch()}
          >
            검색
          </Button>
        </Stack>
      </Stack>
      <Grid container mt={16}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          xs={12}
        >
          <Grid container item alignItems="center" xs={6}>
            <Grid item>
              <TextField
                label="검색"
                size="small"
                value={searchText}
                onChange={(e) => handleChangeSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Button variant="text" onClick={suddenRank}>
                  급상승
                </Button>
                <Button onClick={unResigerFilter}>미등록</Button>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            alignItems="center"
            xs={6}
          >
            <Grid item alignItems="center">
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Fragment>
                  {dataLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </Fragment>
                <Button
                  disabled={checkBtnDisabled(1)}
                  value={1}
                  onClick={(e) => handleClickDateRange(e)}
                >
                  1일 전
                </Button>
                <Button
                  disabled={checkBtnDisabled(3)}
                  value={3}
                  onClick={(e) => handleClickDateRange(e)}
                >
                  3일 전
                </Button>
                <Button
                  disabled={checkBtnDisabled(7)}
                  value={7}
                  onClick={(e) => handleClickDateRange(e)}
                >
                  7일 전
                </Button>
              </Stack>
            </Grid>
            <Grid item alignItems="center">
              <Stack direction="row" alignItems="center" spacing={1}>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={comparisonDate}
                  onChange={(date: Date) =>
                    handleChangeDate(date, "comparison")
                  }
                  includeDates={sourcingDates}
                  showDisabledMonthNavigation
                />
                <span> - </span>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={selectedDate}
                  onChange={(date: Date) => handleChangeDate(date, "selected")}
                  includeDates={sourcingDates}
                  showDisabledMonthNavigation
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {tableData && (
            <CustomTable
              name={"sourcingTable"}
              columns={columns}
              data={tableData}
              onEdit={changeIgnore}
              initialState={initialState}
              ref={tableRef}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SourcingChart;
