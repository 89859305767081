import { ReactNode } from "react";
import * as MdIcons from "react-icons/md";

export interface submenu {
  title: string;
  url: string;
}

export interface topMenu {
  title: string;
  url: string;
  icon: ReactNode;
  submenu?: submenu[];
}

export const menuItemDatas: topMenu[] = [
  {
    title: "가격관리",
    url: "/supply",
    icon: <MdIcons.MdPriceCheck />,
    submenu: [
      {
        title: "공급가 관리",
        url: "supply-manager",
      },
      {
        title: "공급가 순위",
        url: "supply-rank",
      },
      {
        title: "프로모션 관리",
        url: "promo",
      },
    ],
  },
  {
    title: "상품소싱",
    url: "/sourcing",
    icon: <MdIcons.MdOutlineShop />,
    submenu: [
      {
        title: "소싱 차트",
        url: "sourcing/chart",
      },
      {
        title: "소싱 카테고리 관리",
        url: "sourcing/manager",
      },
      {
        title: "소싱 원부 예외",
        url: "sourcing/ignore",
      },
    ],
  },
  {
    title: "상품관리",
    url: "/product",
    icon: <MdIcons.MdOutlineShop />,
    submenu: [
      {
        title: "메인상품",
        url: "product/main",
      },
      {
        title: "자동가비",
        url: "product/gabi",
      },
    ],
  },
  {
    title: "계정관리",
    url: "/account",
    icon: <MdIcons.MdOutlinePeopleAlt />,
    submenu: [
      {
        title: "마이페이지",
        url: "account/mypage",
      },
      {
        title: "발주계정",
        url: "account/order",
      },
    ],
  },
];
