import {
  forwardRef,
  MouseEventHandler,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import {
  CellProps,
  HeaderProps,
  Hooks,
  Row,
  TableInstance,
  TableState,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useDebounce } from "utils/useDebounce";
import { FilterChipBar } from "./FilterChipBar";
import { DefaultColumnFilter } from "./filters";

import { Command, TableToolbar } from "./TableToolbar";
import { useInitialTableState } from "./useInitialTableState";

import {
  TableContainer,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Zoom,
} from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import TablePaginationActions from "./TablePagination";
import {
  HeaderCheckbox,
  RowCheckbox,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableLabel,
  TableRow,
  TableTable,
  useStyles,
} from "./TableStyles";
import { ResizeHandle } from "./ResizeHandle";

interface props {
  name: string;
  columns: any[];
  data: any[];
  onAdd?: (instance: TableInstance<any>, data?: Record<string, any>) => void;
  onDelete?: (instance: TableInstance<any>, data?: Record<string, any>) => void;
  onEdit?: (instance: TableInstance<any>, data?: Record<string, any>) => void;
  onClick?: (row: Row<any>) => void;
  extraCommands?: Command<any>[];
  onRefresh?: MouseEventHandler;
  initialState?: Partial<TableState<any>>;
}

const DEFAULT_PAGE_SIZE = 10;

const CustomTable = forwardRef((props: props, ref) => {
  const {
    columns,
    data,
    name,
    onAdd,
    onDelete,
    onEdit,
    // onClick,
    extraCommands,
    onRefresh,
    initialState: userInitialState = {},
  } = props;

  const { classes, cx } = useStyles();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    gotoPage(0);
  };

  const defaultColumn = useMemo(
    () => ({
      minWidth: 10,
      width: 150,
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [initialState, setInitialState] = useInitialTableState(
    `tableState:${name}`,
    columns,
    {
      pageSize: DEFAULT_PAGE_SIZE,
      ...userInitialState,
    }
  );

  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useResizeColumns,
    useFlexLayout,
    usePagination,
    useRowSelect,
    (hooks: Hooks<any>) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "_selector",
          minWidth: 37,
          width: 37,
          maxWidth: 37,
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
            <HeaderCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: CellProps<any>) => (
            <RowCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    setPageSize,
    prepareRow,
    state,
  } = instance;

  const { pageIndex, pageSize } = state;

  const debouncedState = useDebounce(state, 500);

  useEffect(() => {
    setInitialState(debouncedState);
  }, [setInitialState, debouncedState]);

  useImperativeHandle(ref, () => instance);

  return (
    <TableContainer>
      <TableToolbar
        name={name}
        instance={instance}
        {...{ onAdd, onDelete, onEdit, extraCommands, onRefresh }}
      />
      <FilterChipBar instance={instance} />
      <TableTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            return (
              <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableHeadCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.canSort ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                          {...column.getSortByToggleProps()}
                          className={classes.tableSortLabel}
                        >
                          {column.render("Header")}
                          {column.tooltip && (
                            <Tooltip
                              title={column.tooltip}
                              placement="top"
                              arrow
                              TransitionComponent={Zoom}
                            >
                              <QuestionMarkIcon fontSize="small" color="info" />
                            </Tooltip>
                          )}
                        </TableSortLabel>
                      ) : (
                        <TableLabel>
                          {column.render("Header")}
                          {column.tooltip && (
                            <Tooltip
                              title={column.tooltip}
                              placement="top"
                              arrow
                              TransitionComponent={Zoom}
                            >
                              <QuestionMarkIcon fontSize="small" color="info" />
                            </Tooltip>
                          )}
                        </TableLabel>
                      )}
                      {column.canResize && <ResizeHandle column={column} />}
                      {/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
                    </TableHeadCell>
                  );
                })}
              </TableHeadRow>
            );
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: Row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                className={cx({
                  rowSelected: row.isSelected,
                })}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className="singleline-ellipsis"
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              colSpan={3}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableBody>
      </TableTable>
      <div>
        <pre>
          <code>{JSON.stringify(state, null, 2)}</code>
        </pre>
      </div>
    </TableContainer>
  );
});

export default CustomTable;
