import { useMemo } from "react";
import { FilterProps } from "react-table";
import { TextField, MenuItem } from "@mui/material";

export const SelectColumnFilter = ({
  column: { filterValue, render, setFilter, preFilteredRows, id },
}: FilterProps<any>) => {
  const options = useMemo(() => {
    const options = new Set<any>();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...Array.from(options.values())];
  }, [id, preFilteredRows]);

  return (
    <TextField
      select
      label={render("Header")}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      variant="standard"
    >
      <MenuItem value={""}>All</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};
