import axios from "axios";
import notify from "utils/notify";
import { defaultInstance } from "../customAxios";

const BaseURL = "pm";

export const getProductAll = async () => {
  try {
    const { data } = await defaultInstance.get<[]>(`${BaseURL}/product/all/`);
    return data;
  } catch (error) {
    notify.error("상품 목록 가져오기 실패");
    console.error(error);
    return [];
  }
};

export const getProductAllTemp = async () => {
  try {
    const { data } = await axios.get<[]>(
      "http://cheerupkorea.iptime.org:9191/product/all"
    );
    return data;
  } catch (error) {
    notify.error("상품 목록 가져오기 실패");
    console.error(error);
    return [];
  }
};
