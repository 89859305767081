import { DetailField, DialogField, InputField } from "./Interface";

export const inputDefault: InputField = {
  type: "text",
  child: [],
};

export const detailDefault: DetailField = {
  isHidden: false,
  isDisabled: false,
  isRequired: false,
  isMulti: false,
};

export const dialogDefault: DialogField = {
  input: inputDefault,
  create: detailDefault,
  update: detailDefault,
  delete: detailDefault,
};
