import "./signForm.scss";
import { useState } from "react";

interface SignFormData {
  name: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignForm = () => {
  const [authMode, setAuthMode] = useState("signin");
  const [signFormData, setSignFormData] = useState<SignFormData>({
    name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };

  const handelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignFormData({
      ...signFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(authMode);
    if (authMode === "signin") {
      const { username, password } = signFormData;
      console.log(username, password);
    } else {
      const { username, email, password, confirmPassword } = signFormData;
      if (password !== confirmPassword) {
        setErrorMessage("비밀번호가 다릅니다.");
        return;
      }
      setErrorMessage("");
    }
  };

  return (
    <div className="auth-form-container">
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="auth-form-content">
          <h3 className="auth-form-title">
            {authMode === "signin" ? "로그인" : "회원가입"}
          </h3>
          <div className="form-group">
            <label htmlFor="username"></label>
            <input
              type="text"
              className="form-control"
              name="username"
              placeholder="아이디를 입력해주세요"
              value={signFormData.username}
              onChange={handelInputChange}
            />
          </div>
          {authMode === "signin" ? (
            ""
          ) : (
            <>
              <div className="form-group">
                <label htmlFor="name"></label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="이름을 입력해주세요"
                  value={signFormData.name}
                  onChange={handelInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email"></label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="이메일을 입력해주세요"
                  value={signFormData.email}
                  onChange={handelInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword"></label>
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  placeholder="비밀번호를 입력해주세요"
                  value={signFormData.confirmPassword}
                  onChange={handelInputChange}
                />
              </div>
            </>
          )}
          <div className="form-group">
            <label htmlFor="password"></label>
            <input
              type="password"
              className="form-control"
              name="password"
              placeholder="비밀번호를 입력해주세요"
              value={signFormData.password}
              onChange={handelInputChange}
            />
          </div>
          {errorMessage && <div>{errorMessage}</div>}
          <div className="d-grid">
            <button type="submit">
              {authMode === "signin" ? "로그인" : "회원가입"}
            </button>
          </div>
          <div className="text-center mt-3">
            <span className="link-primary" onClick={changeAuthMode}>
              {authMode === "signin" ? "회원가입" : "로그인"}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignForm;
