import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Container, Box, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getIgnore, putIgnore } from "apis/sourcing/sourcing";
import CustomTable from "components/CustomTable/CustomTable";
import { TableInstance } from "react-table";
import { IgnoreWonbuData } from "../interfaces";
import { format, zonedTimeToUtc } from "date-fns-tz";
import notify from "utils/notify";

const SourcingIgnore = () => {
  const [ignoreWonbu, setIgnoreWonbu] = useState<IgnoreWonbuData[]>([]);

  const [searchText, setSearchText] = useState(""); // 글로벌 검색 텍스트

  // CRUD 구성
  const fectchIgnoreWonbu = async () => {
    const parmas = { page: 1, pageSize: 100 };
    const { result } = await getIgnore(parmas);
    setIgnoreWonbu(result);
  };

  const handleChangeSearch = (text: string) => {
    setSearchText(text);
    if (tableRef.current) {
      tableRef.current.setGlobalFilter(text);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessor: "id",
        Header: "ID",
        disableFilters: true,
        minWidth: 40,
        width: 40,
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "code",
        Header: "CODE",
        minWidth: 40,
        width: 40,
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "name",
        Header: "Name",
        minWidth: 120,
        width: 120,
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "title",
        Header: "Title",
        minWidth: 120,
        width: 120,
        dialog: { update: { isDisabled: true } },
      },
      {
        accessor: "registerAt",
        Header: "등록일",
        minWidth: 100,
        width: 100,
        Cell: ({ cell: { value } }: any) => {
          return format(zonedTimeToUtc(value, "UTC"), "yyyy.MM");
        },
        dialog: {
          input: { type: "date" },
          update: { isDisabled: true },
        },
      },
      {
        accessor: "updateAt",
        Header: "마지막 수정일",
        minWidth: 100,
        width: 100,
        Cell: ({ cell: { value } }: any) => {
          return format(zonedTimeToUtc(value, "UTC"), "yyyy.MM.dd HH:mm");
        },
        dialog: {
          input: { type: "datetime-local" },
          update: { isDisabled: true },
        },
      },
    ],
    []
  );
  const data = useMemo(() => ignoreWonbu, [ignoreWonbu]);

  const initialState = {
    pageSize: 10,
    filters: [],
  };

  const tableRef = useRef<any>(null);

  const changeIgnore = useCallback(
    async (instance: TableInstance<IgnoreWonbuData>) => {
      const count = instance.selectedFlatRows.length;
      const data = instance.selectedFlatRows.map((v) => {
        return { id: v.original.id, ignore: false };
      });
      const filteredData = ignoreWonbu.filter(
        (itemB) => !data.some((itemA) => itemA.id === itemB.id)
      );
      await putIgnore(data);
      notify.success(count + "개 예외 처리 취소 성공");
      setIgnoreWonbu(filteredData);
    },
    [ignoreWonbu]
  );

  useEffect(() => {
    fectchIgnoreWonbu();
  }, []);

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Container
          sx={{
            marginTop: "2rem",
            width: "80%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TextField
            label="검색"
            size="small"
            value={searchText}
            onChange={(e) => handleChangeSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Container>
        <Box sx={{ width: "100%", marginTop: "2rem" }}>
          {data && (
            <CustomTable
              name={"sourcingIgnoreTable"}
              columns={columns}
              data={data}
              onEdit={changeIgnore}
              initialState={initialState}
              ref={tableRef}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default SourcingIgnore;
