import { menuItemDatas } from "./menuItemDatas";
import MenuItem from "./MenuItem";
import './Navbar.scss'

const Navbar = () => {
  return (
    <>
      <ul className="ck-nav-wrap">
        {menuItemDatas.map((menu, index) => {
          return <MenuItem menu={menu} key={index} />;
        })}
      </ul>
    </>
  );
};

export default Navbar;