import axios from "axios";
import { AxiosRequestConfig } from "axios";

const BASE_URL = "http://cheerupkorea100.iptime.org/api/v1";

const axiosApi = (url: string, config?: AxiosRequestConfig) => {
  const instance = axios.create({
    baseURL: url,
    ...config,
  });

  instance.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      return response;
    },

    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
};

const axiosAuthApi = (url: string, config?: AxiosRequestConfig) => {
  const instance = axios.create({
    baseURL: url,
    ...config,
  });

  instance.interceptors.request.use(
    function (config) {
      // const accessToken = storage.get("access_token");
      // config.headers["Authorization"] = "Bearer " + accessToken;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return instance;
};

export const defaultInstance = axiosApi(BASE_URL);
export const authInstance = axiosAuthApi(BASE_URL);
