import { Route, Routes } from "react-router-dom";
import MyPage from "./MyPage";
import OrderAccount from "./orderAccount";

const Account = () => {
  return (
    <Routes>
      <Route path="mypage" element={<MyPage />}></Route>
      <Route path="order" element={<OrderAccount />}></Route>
    </Routes>
  );
};

export default Account;
