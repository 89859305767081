import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Container, Box, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  delectSearchData,
  getSearchDatas,
  postSearchData,
} from "apis/sourcing/sourcing";
import CustomTable from "components/CustomTable/CustomTable";
import { CellProps, TableInstance } from "react-table";
import { SearchData } from "../interfaces";
import { SelectColumnFilter } from "components/CustomTable/filters/SelectColumnFilter";

const SourcingManager = () => {
  const [sourcingCategorys, setSourcingCategorys] = useState<SearchData[]>([]);

  const [searchText, setSearchText] = useState(""); // 글로벌 검색 텍스트

  // CRUD 구성
  const fectchSourcingCategorys = async () => {
    const data = await getSearchDatas();
    setSourcingCategorys(data);
  };

  const handleChangeSearch = (text: string) => {
    setSearchText(text);
    if (tableRef.current) {
      tableRef.current.setGlobalFilter(text);
    }
  };

  const siteItems = useMemo(
    () => [
      { value: "naver", text: "naver" },
      { value: "danawa", text: "danawa" },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
        minWidth: 40,
        width: 40,
        dialog: { create: { isHidden: true }, update: { isDisabled: true } },
      },
      {
        Header: "SITE",
        accessor: "site",
        Filter: SelectColumnFilter,
        minWidth: 40,
        width: 40,
        dialog: {
          input: {
            type: "select",
            child: siteItems,
          },
          update: { isDisabled: true },
        },
      },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 120,
        width: 120,
        dialog: { create: { isRequired: true } },
      },
      {
        Header: "URL",
        accessor: "url",
        minWidth: 400,
        width: 400,
        Cell: ({ cell: { value } }: CellProps<SearchData>) => {
          return (
            <a href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          );
        },
        dialog: {
          input: { type: "url" },
          create: { isRequired: true },
          update: { isDisabled: true },
        },
      },
    ],
    [siteItems]
  );
  const data = useMemo(() => sourcingCategorys, [sourcingCategorys]);

  const initialState = {
    pageSize: 10,
    filters: [],
  };

  const tableRef = useRef<any>(null);

  const addSourcingCategory = useCallback(
    async (
      instance: TableInstance<SearchData>,
      data?: Record<string, string>
    ) => {
      if (data) {
        const { site, url, name } = data;
        if (url && name) {
          const createData: SearchData = {
            site: site || "naver",
            url: url,
            name: name,
          };
          await postSearchData(createData);
          await fectchSourcingCategorys();
        }
      }
    },
    []
  );

  const updateSourcingCategory = useCallback(
    async (
      instance: TableInstance<SearchData>,
      data?: Record<string, unknown>
    ) => {
      console.log("updateTest");
      console.log(data);
      console.log(
        "Selected",
        instance.selectedFlatRows
          .map((v) => `'${v.original.id} ${v.original.name}'`)
          .join(", ")
      );
    },
    []
  );

  const deleteSourcingCategory = useCallback(
    async (instance: TableInstance<SearchData>) => {
      // foreach는 await 이 작동 하지 않을 수 있음
      const promises = instance.selectedFlatRows.map(async (v) => {
        if (v.original.id) {
          await delectSearchData(v.original.id);
        }
      });
      await Promise.all(promises);
      await fectchSourcingCategorys();
    },
    []
  );

  useEffect(() => {
    fectchSourcingCategorys();
  }, []);

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Container
          sx={{
            marginTop: "2rem",
            width: "80%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TextField
            label="검색"
            size="small"
            value={searchText}
            onChange={(e) => handleChangeSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Container>
        <Box sx={{ width: "100%", marginTop: "2rem" }}>
          {data && (
            <CustomTable
              name={"sourcingCategoryTable"}
              columns={columns}
              data={data}
              onAdd={addSourcingCategory}
              onEdit={updateSourcingCategory}
              onDelete={deleteSourcingCategory}
              initialState={initialState}
              ref={tableRef}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default SourcingManager;
