import notify from "utils/notify";
import { defaultInstance } from "../customAxios";

const BaseURL = "ac/user";

export const getSiteList = async () => {
  try {
    const { data } = await defaultInstance.get<[]>(`${BaseURL}/site/`, {
      headers: { x_referer: "home", x_task: "read" },
    });
    return data;
  } catch (error) {
    notify.error("사이트 목록 가져오기 실패");
    console.error(error);
    return [];
  }
};

export const getStateList = async () => {
  try {
    const { data } = await defaultInstance.get<[]>(`${BaseURL}/state/`, {
      headers: { x_referer: "home", x_task: "read" },
    });
    return data;
  } catch (error) {
    notify.error("사이트 목록 가져오기 실패");
    console.error(error);
    return [];
  }
};

export const getAccountList = async (num: string) => {
  try {
    const { data } = await defaultInstance.get<[]>(
      `${BaseURL}/account/${num}/all/`,
      {
        headers: { x_referer: "home", x_task: "read" },
      }
    );
    return data;
  } catch (error) {
    notify.error("사이트 계정 목록 가져오기 실패");
    console.error(error);
    return [];
  }
};

export const postAccount = async (data: any) => {
  try {
    const res = await defaultInstance.post(`${BaseURL}/account/`, data, {
      headers: { x_referer: "home", x_task: "create" },
    });
    return res.data;
  } catch (error) {
    notify.error("사이트 계정 생성 실패");
    console.error(error);
  }
};

export const updateAccount = async (data: any) => {
  try {
    const res = await defaultInstance.put(`${BaseURL}/account/`, data, {
      headers: { x_referer: "home", x_task: "update" },
    });
    return res.data;
  } catch (error) {
    notify.error("사이트 계정 수정 실패");
    console.error(error);
  }
};

export const deleteAccount = async (site_id: number, idNum: number) => {
  try {
    const { data } = await defaultInstance.delete(
      `${BaseURL}/account/${site_id}/${idNum}/`,
      {
        headers: { x_referer: "home", x_task: "delete" },
      }
    );
    return data;
  } catch (error) {
    notify.error("사이트 계정 삭제 실패");
    console.error(error);
    return [];
  }
};
