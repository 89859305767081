const MyPage = () => {
  return (
    <div>
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
      마이페이지
      <br />
    </div>
  );
};

export default MyPage;
