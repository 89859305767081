import { toast } from "react-toastify";

const notify = {
  success: (text, config = null) => {
    toast.success(text, config);
  },
  error: (text, config = null) => {
    if (!config) {
      config = { autoClose: 5000 };
    }
    toast.error(text, config);
  },
};

export default notify;
